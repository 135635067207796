import { Box, Button, chakra, Container, Flex, Grid, Heading, Tag, Text } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/system";
import { NextSeo } from "next-seo";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Layout from "../components/UI/Marketing";
import community from "../public/illustrations/Community.png";
import integrate from "../public/illustrations/integrate.png";
import interactive from "../public/illustrations/interactive.png";
import heroImage from "../public/illustrations/New.Hero.png";
import track from "../public/illustrations/Track.png";
import understanding from "../public/illustrations/Understanding.png";

const cardStackStyles: SystemStyleObject = {
  pos: "relative",
  bg: "white",
  p: 3,
  boxShadow: "0 0 1px #aaa",
  borderRadius: "6px",
};

const Home = () => {
  return (
    <>
      <NextSeo
        title="Everything you need to host cohort-based classes"
        description="Learning has never been this much immersive, interactive, and engaging."
        canonical="https://learntific.com"
        openGraph={{
          type: "website",
          title: "Everything you need to host cohort-based classes",
          description: "We're building the future of interactive learning. Learning has never been this much immersive and engaging.",
        }}
      />
      <Layout>
        <Flex bg="black" maxW="100%" as="header" minH="80vh" flexDir="column" pos="relative" color="white">
          <chakra.div
            pos="relative"
            display="flex"
            flexDir="column"
            width="100%"
            maxW="90ch"
            pb={{ base: 10, md: 10 }}
            pt={20}
            px={6}
            m="auto">
            <Text
              as="h1"
              fontSize={{ base: "32px", lg: "5xl", xxl: "62px" }}
              fontWeight="900"
              color="white"
              lineHeight={1.2}
              maxW="20ch"
              mx="auto"
              letterSpacing="-0.082rem"
              textAlign="center"
              mb={4}>
              Everything you need to host cohort-based classes
            </Text>
            <Text fontSize={{ base: "xl" }} fontWeight="500" color="gray.200" textAlign="center" maxW="50ch" mx="auto">
              Get the tools you need to host interactive and engaging learning: lesson authoring, students management, messaging, mixed
              reality and more.
            </Text>
          </chakra.div>
          <Flex flexDir={{ base: "column", md: "row" }} mx={{ md: "auto" }}>
            <Link passHref href="https://app.learntific.com/create">
              <Button
                mt={{ base: 3, md: 0 }}
                as="a"
                colorScheme="green"
                d={{ base: "none", md: "inline-flex" }}
                size="lg"
                color="black"
                variant="solid"
                mx="auto"
                mb={{ base: 4, lg: 0 }}
                borderRadius="14px">
                Create a Class
              </Button>
            </Link>
            <Link passHref href="discover">
              <Button
                mt={{ base: 3, md: 0 }}
                as="a"
                colorScheme="green"
                d={{ base: "inline-flex", md: "none" }}
                size="lg"
                color="black"
                variant="solid"
                mx="auto"
                mb={{ base: 4, lg: 0 }}
                borderRadius="14px">
                Join a Class
              </Button>
            </Link>
          </Flex>
          <chakra.div pos="relative" zIndex={150}>
            <chakra.figure
              pos={{ base: "relative" }}
              w={{ base: "100%" }}
              h="auto"
              __css={{
                "> div:first-of-type": {
                  h: { base: "330px", md: "644px" },
                  w: "100%",
                },
                "> div:first-of-type > img": {
                  objectFit: { base: "cover", md: "contain" },
                  objectPosition: "top center",
                },
              }}>
              <Image src={heroImage} loading="eager" placeholder="blur" quality={100} layout="responsive" />
            </chakra.figure>
          </chakra.div>
        </Flex>

        <Container as="section" maxW={{ md: "90vw", xxl: "84vw" }} px={6} py={{ base: 16, lg: 40 }}>
          <Grid templateColumns={{ base: "1fr", md: "1fr 0.25fr" }} gridGap={8} pb={16}>
            <Box>
              <Text
                fontWeight="900"
                as="h3"
                mb={2}
                fontSize={{ base: "3xl", lg: "5xl" }}
                color="black"
                lineHeight={1.2}
                letterSpacing={{ lg: "-0.062rem" }}>
                You’re in charge. Here’s what you can do with Learntific
              </Text>
            </Box>
          </Grid>
          <Grid gridTemplateColumns={{ md: "repeat(2,1fr)" }} gridGap={6} mb={6}>
            {/*  */}
            <chakra.div bg="rgba(0,0,0,.04)" boxShadow="0px 0px 1px rgba(0,0,0,.2)" borderRadius="14px" overflow="hidden">
              <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="700" px={{ base: 8, md: 16 }} pt={16} mb={8}>
                Every class you create is a community with group and 1-on-1 discussions.
              </Text>
              <chakra.figure
                ml={{ base: 8, md: 16 }}
                minH={{ base: "200px", md: "400px" }}
                borderRadius="10px 0 0 0"
                boxShadow="3px 3px 20px 0 rgba(0,0,0,.02),0 0 40px 0 rgba(0,0,0,.07)">
                <Image src={community} loading="lazy" placeholder="blur" quality={100} layout="responsive" />
              </chakra.figure>
            </chakra.div>
            {/*  */}
            <chakra.div bg="rgba(0,0,0,.04)" boxShadow="0px 0px 1px rgba(0,0,0,.2)" borderRadius="14px" overflow="hidden">
              <chakra.figure
                ml={{ base: 8, md: 16 }}
                minH={{ base: "200px", md: "400px" }}
                borderRadius="0 0 0 10px"
                boxShadow="3px 3px 20px 0 rgba(0,0,0,.02),0 0 40px 0 rgba(0,0,0,.07)">
                <Image src={track} loading="lazy" placeholder="blur" quality={100} layout="responsive" />
              </chakra.figure>
              <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="700" px={{ base: 8, md: 16 }} pb={16} mt={8}>
                Easily track and measure students engagement and learning impact.
              </Text>
            </chakra.div>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridGap={6} mb={6}>
            {/*  */}
            <chakra.div
              bg="rgba(0,0,0,.04)"
              boxShadow="0px 0px 1px rgba(0,0,0,.2)"
              borderRadius="14px"
              overflow="hidden"
              pt={16}
              d={{ md: "flex" }}>
              <Text
                fontSize={{ base: "xl", lg: "2xl" }}
                fontWeight="700"
                px={{ base: 8, md: 16 }}
                pb={{ base: 8, md: 16 }}
                my="auto"
                w={{ md: "40%" }}>
                Create assessments and grade students understanding.
              </Text>
              <chakra.figure
                w={{ md: "60%" }}
                mx={{ base: 4, md: "auto" }}
                minH={{ base: "140px", md: "400px" }}
                borderRadius="10px 0 0 0"
                boxShadow="3px 3px 20px 0 rgba(0,0,0,.02),0 0 40px 0 rgba(0,0,0,.07)">
                <Image src={understanding} loading="lazy" placeholder="blur" quality={100} layout="responsive" />
              </chakra.figure>
            </chakra.div>
          </Grid>
          {/*  */}
          <Grid gridTemplateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gridGap={6}>
            <chakra.div bg="rgba(0,0,0,.04)" boxShadow="0px 0px 1px rgba(0,0,0,.2)" borderRadius="14px" overflow="hidden">
              <chakra.article px={{ base: 8, md: 16 }} pt={16} mb={8}>
                <Tag colorScheme="green" variant="solid" borderRadius="full" color="black" fontWeight="700" mb={2}>
                  Coming Soon
                </Tag>
                <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="700">
                  Integrate your familiar platforms with Learntific
                </Text>
              </chakra.article>
              <chakra.figure
                ml={{ base: 8, md: 16 }}
                minH={{ base: "200px", md: "400px" }}
                borderRadius="14px 0 0 14px"
                boxShadow="3px 3px 20px 0 rgba(0,0,0,.02),0 0 40px 0 rgba(0,0,0,.07)">
                <Image src={integrate} loading="lazy" placeholder="blur" quality={100} layout="responsive" />
              </chakra.figure>
            </chakra.div>
            <chakra.div bg="rgba(0,0,0,.04)" boxShadow="0px 0px 1px rgba(0,0,0,.2)" borderRadius="14px" overflow="hidden">
              <chakra.figure
                ml={{ base: 8, md: 16 }}
                minH={{ base: "200px", md: "400px" }}
                borderRadius="0 0 0 14px"
                boxShadow="3px 3px 20px 0 rgba(0,0,0,.02),0 0 40px 0 rgba(0,0,0,.07)">
                <Image src={interactive} loading="lazy" placeholder="blur" quality={100} layout="responsive" />
              </chakra.figure>
              <chakra.article px={{ base: 8, md: 16 }} pb={16} mt={8}>
                <Tag colorScheme="green" variant="solid" borderRadius="full" color="black" fontWeight="700" mb={2}>
                  Coming Soon
                </Tag>
                <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="700">
                  Web-based augmented reality tools and content to help learners understand complex concepts better
                </Text>
              </chakra.article>
            </chakra.div>
          </Grid>
        </Container>

        {/*  */}
        <Container as="section" maxW={{ md: "90vw", xxl: "84vw" }} pos="relative" py={{ base: 20, lg: 24 }} px={6}>
          <Flex flexDir="row" flexWrap="wrap" pos="relative" justifyContent="center">
            <Box width={{ base: "100%", lg: "70%" }} mt={{ base: 8, lg: 10 }}>
              <Heading fontWeight="900" color="blue.200" mb={2} fontSize={{ base: "2xl", lg: "3xl" }}>
                Our Manifesto
              </Heading>
              <Text
                fontWeight="800"
                letterSpacing="-0.05rem"
                fontSize={{ base: "3xl", lg: "5xl" }}
                color="blue.500"
                lineHeight={1.2}
                mb={8}>
                We're on a mission to extend the reality of learning online
              </Text>
            </Box>
            <Box width={{ base: "100%", lg: "40%" }}>
              <chakra.p fontSize={{ base: "18px", md: "xl" }} mb={6}>
                Traditional classrooms are beginning to feel like boxed-up spaces! Now, more than ever before, educators need to be
                receptive to new teaching methods that are more efficient and exciting. Learntific is making emerging web-based technologies
                like Augmented Reality (AR) accessible for both educators and learners.
              </chakra.p>
              <chakra.p fontSize={{ base: "18px", md: "xl" }} mb={{ base: 6, md: 0 }}>
                Learntific is building the future of interactive learning in which learners will have fun while learning. For everyone,
                learning should be easier and exciting. We wish to bring delightful and illuminating experiences to both learners and
                educators. Everyone can focus on learning and have fun while at it.
              </chakra.p>
            </Box>
            <Box w={{ base: "100%", md: "30%" }} d="flex">
              <chakra.article
                px={4}
                borderLeft="2px solid"
                borderLeftColor="yellow.500"
                bg="yellow.50"
                py={4}
                maxW={{ md: "26ch" }}
                fontSize="md"
                mt="auto"
                ml="auto">
                Virtual connections are beginning to feel more realistic than ever before using WebXR...we now have the flexibility to
                immediately use immersive technology in our browsers.
              </chakra.article>
            </Box>
          </Flex>
        </Container>

        <chakra.section as="section" pos="relative" py={{ base: 20, lg: 24 }} px={6}>
          <Grid templateColumns={{ md: "repeat(2,1fr)" }} gap={6} textAlign="center">
            <chakra.article bg="black" color="white" borderRadius="16px" px={{ base: 10, md: 20 }} py={{ base: 16, md: 24 }}>
              <Tag colorScheme="green" variant="solid" borderRadius="full" color="black" fontWeight="700" mb={4} size="xl" h="30px" px={3}>
                For Instructors
              </Tag>
              <Heading mb={8}>Take Learntific for a spin, it's free to get started.</Heading>
              <Link passHref href="https://app.learntific.com">
                <Button
                  as="a"
                  variant="outline"
                  size="lg"
                  colorScheme="gray"
                  color="white"
                  borderColor="white"
                  mx="auto"
                  _hover={{ bg: "white", color: "black", borderColor: "white" }}>
                  Create a Class
                </Button>
              </Link>
            </chakra.article>
            <chakra.article bg="green.500" color="black" borderRadius="16px" px={{ base: 10, md: 20 }} py={{ base: 16, md: 24 }}>
              <Tag
                variant="solid"
                borderRadius="full"
                color="green.500"
                colorScheme="blue"
                fontWeight="700"
                mb={4}
                size="xl"
                h="30px"
                px={3}>
                For Learners
              </Tag>
              <Heading mb={8}>Experience exciting and engaging learning</Heading>
              <Link passHref href="/login">
                <Button
                  as="a"
                  variant="outline"
                  size="lg"
                  borderRadius="14px"
                  colorScheme="blue"
                  _hover={{ bg: "blue.500", color: "green.500", borderColor: "blue.500" }}
                  mx="auto">
                  Login
                </Button>
              </Link>
            </chakra.article>
          </Grid>
        </chakra.section>
      </Layout>
    </>
  );
};

export default Home;
