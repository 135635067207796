import { Button, chakra, Container, Flex, HStack, Text } from "@chakra-ui/react";
import Link from "next/link";
import React, { ReactNode } from "react";
import Navbar from "./Navbar";

type Props = {
  children?: ReactNode;
  title?: string;
  description?: string;
  position?: string;
  imageURL?: string;
};

const Layout = ({ children, title, description, position, imageURL }: Props) => (
  <>
    <Navbar position={position} />

    {children}

    {/*  */}
    <Container as="footer" maxW={{ md: "90vw", xxl: "84vw" }} px={{ base: 6 }}>
      <Flex color="gray.500" fontWeight="500" flexWrap="wrap" mb={4}>
        <HStack spacing={1} role="group" mx="auto" flexWrap="wrap" justify="center">
          <Link href="mailto:hey@learntific.com" passHref>
            <Button as="a" minH="40px" borderRadius="full" variant="ghost" _hover={{ bg: "gray.100" }}>
              Contact Us
            </Button>
          </Link>
          <Link href="/privacy" passHref>
            <Button as="a" minH="40px" borderRadius="full" variant="ghost" _hover={{ bg: "gray.100" }}>
              Privacy Policy
            </Button>
          </Link>
          <Link href="/terms" passHref>
            <Button as="a" minH="40px" borderRadius="full" variant="ghost" _hover={{ bg: "gray.100" }}>
              Terms of Service
            </Button>
          </Link>
        </HStack>
      </Flex>
      <Flex flexDir="column" h="100%" pb={16} alignItems="center">
        <Text as="p" textAlign="center" maxW="26ch" mb={10}>
          &copy; {new Date().getFullYear()} - Learntific. All Rights Reserved. Made with ♥ in Montreal.
        </Text>
        <chakra.svg w="80px" h="auto" viewBox="0 0 512 512" mb={10} color="black">
          <path
            d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28Z"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28ZM123.156,118.7,94.247,144.3V337.89l76.239,76.242H365.309l24.04-21.281H226.512a17.355,17.355,0,0,1-12.263-5.08l-84.28-84.28a17.338,17.338,0,0,1-5.076-12.088Z"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            d="M140.116,289.117V80.531H389.058L435.1,122.672V375.511H226.516Zm30.348-178.238V276.547l68.622,68.616H404.749V136.037l-27.484-25.158Z"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path d="M279.324,185.949H239.263v88.892H329.75V237.172H279.324Z" fill="currentColor" />
        </chakra.svg>
      </Flex>
    </Container>
  </>
);

export default Layout;
